import AuthGuard from "@/helpers/AuthGuard";

export default [
  {
    path: "/",
    beforeEnter: AuthGuard.withCompleteOnboarding,
    meta: { title: "deals.meta", layout: "AuthenticatedLayout" },
    children: [
      {
        path: "deal/:dealId",
        name: "DealOverview",
        component: () => import("@/modules/deal/views/DealOverview.vue"),
      },
    ],
  },
];

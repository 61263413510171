import { ref, watch } from "vue";
import { defineStore } from "pinia";

export const useLoader = defineStore("useLoader", () => {
  // State to manage the loader visibility
  const isLoading = ref(false);

  // Methods to control the loader state
  function startLoading() {
    isLoading.value = true;
  }

  function stopLoading() {
    isLoading.value = false;
  }

  return {
    isLoading,
    startLoading,
    stopLoading,
  };
});
